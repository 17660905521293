header {
  background-color: $gray;
  background: no-repeat center center;
  background-attachment: scroll;
  @include background-cover();

  // NOTE: Background images are set within the HTML using inline CSS
  margin-bottom: 50px;

  .ds-heading {
    padding: 100px 0 50px;
    color: white;
    text-align: center;
    h1 {
      margin-top: 0;
      font-size: 50px;
    }
    .ds-subheading {
      font-size: 24px;
      line-height: 1.1;
      display: block;
      @include sans-serif();
      font-weight: 300;
      margin: 10px 0 0;
    }
    @media only screen and (min-width: 768px) {
      padding: 150px 0;

      h1 {
        font-size: 65px;
      }
    }
  }
}
